<template>
  <div class="index">
    <!-- <div class="topNav">
      <span>牧予生活营销管理系统</span>
      <div class="user">
        <img @error="headError" v-if="headeShow" :src="userInfo?userInfo.headerImg:''" alt="">
        <img v-else src="../assets/image/head.png" alt="">
        <span @click="loginOut">退出登录</span>
      </div>
    </div> -->
    <div class="indexBox">
      <div class="leftNav">
        <leftNav></leftNav>
      </div>
      <div class="right">
        <router-view/>
      </div>
    </div>
  </div>
</template>
<script>
  import leftNav from '@/components/leftNav';
  export default {
    name: "index",
    components: {
      leftNav
    },
    data() {
      return {
        userInfo:null,
        headeShow:true
      };
    },
    created(){
       let userInfo = window.localStorage.getItem('userInfo');
       if(userInfo){
         this.$store.commit('setUserInfo',JSON.parse(userInfo))
       }
    },
    methods:{
      loginOut(){
          window.localStorage.removeItem('token');
          window.localStorage.removeItem('userInfo');
          this.$router.replace('/login',()=>{
            window.location.reload()
          })
      },
      headError(){
        this.headeShow = false
      }
    }
  };
</script>
<style lang="scss" scoped>
  .index {
    overflow: hidden;
    .topNav {
      background: #FFFFFF;
      height: 50px;
      font-size: 16px;
      font-family: MicrosoftYaHei-Bold;
      font-weight: bold;
      color: #333333;
      display: flex;
      align-items: center;
      padding: 0 50px;
      box-sizing: border-box;
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.16);
      position: sticky;
      left: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      z-index:500;
      .user{
        img{
          width: 30px;
          height: 30px;
          border-radius: 50%;
          margin-right: 15px;
        }
        span{
          cursor: pointer;
          font-size: 12px;
        }
      }
    }
    .indexBox {
      display: flex;
      .leftNav {
        width: 190px;
        background: #FFFFFF;
        height: calc(100vh - 50px);
        border-right: 1px solid #DEDEDE;
      }
      .right {
        width: calc(100% - 190px);
        height: 100vh;
        padding: 0 20px;
        background: #FFFFFF;
        overflow: auto;
      }
    }
  }
</style>
