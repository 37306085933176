<template>
  <div class="leftNav">
    <div class="title">
      营销管理中心
    </div>
    <div class="box">
      <div class="list" :class="act.includes('client')?'act':''" @click="switchover('/client/list')">
        客户管理
      </div>
      <div class="list" :class="act.includes('propur')?'act':''"  @click="switchover('/propur/prolist')">
        产品购买管理
      </div>
      <div class="list" :class="act.includes('proMan')?'act':''" @click="switchover('/proMan/prolist')">
        产品管理
      </div>
      <div class="list" :class="act.includes('banMan')?'act':''" @click="switchover('/banMan/bannerlist')">
        banner管理
      </div>
      <div class="list" :class="act.includes('bankingManagement')?'act':''" @click="switchover('/bankingManagement/list')">
        收款银行管理
      </div>
      <div class="list" :class="act.includes('proceedsCode')?'act':''" @click="switchover('/proceedsCode/list')">
        收款码管理
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'leftNav',
    data() {
      return {
         act:'/client'
      };
    },
    watch:{
       '$route':{
         handler(e){
           this.act = e.fullPath
         },
         immediate:true
       }
    },
    methods: {
      switchover (url) {
        this.$router.push({ path: url });
      }
    }
  };
</script>
<style lang="scss" scoped>
  .leftNav {
    box-sizing: border-box;
    .title {
      height: 48px;
      line-height: 48px;
      padding-left: 16px;
      border-bottom: 1px solid #DEDEDE;
      font-size: 14px;
      font-family: MicrosoftYaHei-Bold;
      font-weight: bold;
      color: #333333;
    }
    .box {
      .list {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        padding-left: 16px;
        font-size: 12px;
        color: #666666;
        cursor: pointer;
        &.act {
          color: #3D7EFF;
        }
        &:hover {
          color: #3D7EFF;
        }
      }
    }
  }
</style>
